<template lang="html">
  <div class="recordDetail" v-if="detail">
    <div class="box">
      <img :src="getBillImg(detail.bill_offline_key)" alt />
      <div class="title">{{ detail.market_name }}</div>
      <div class="list">
        <div class="left">付款方式</div>
        <div class="right">{{ payType() }}</div>
      </div>
      <div class="list">
        <div class="left">付款金额</div>
        <div class="right">{{ detail.key_pay_price || 0 }}元</div>
      </div>
      <div class="list">
        <div class="left">缴费说明</div>
        <div class="right">{{ detail.bill_offline_name }}</div>
      </div>
      <div class="list">
        <div class="left">付款时间</div>
        <div class="right">{{ detail.pay_time }}</div>
      </div>
      <div class="list">
        <div class="left">订单号</div>
        <div class="right">{{ detail.bill_offline_no }}</div>
      </div>
      <div class="list">
        <div class="left">缴费凭证</div>
        <div
          class="right"
          @click="$router.push({ path: '/contribute/recordVoucher', query: { id: id } })"
        >
          <span>查看</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'recordDetail',
  data() {
    return {
      id: '',
      detail: null
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$require
        .HttpPost('/h5/payment/getPayHistoryMessage', { bill_offline_id: this.id })
        .then((res) => {
          this.detail = res.data;
        });
    },
    getBillName(key) {
      switch (key) {
        case 10:
          return '水费';
        case 20:
          return '电费';
        case 30:
          return '气费';
        case 40:
          return '商铺租金';
        default:
          return '';
      }
    },
    payType() {
      switch (this.detail.pay_type) {
        case 10:
          return '微信';
        case 20:
          return '支付宝';
        case 30:
          return '现金';
        case 97:
          return '余额';
        case 98:
          return '银行卡';
        case 99:
          return '花呗';
        default:
          return '';
      }
    },
    getBillImg(key) {
      switch (key) {
        case 20:
          return require('../../assets/image/shui.png');
        case 10:
          return require('../../assets/image/zujinIcon.png');
        default:
          return require('../../assets/image/other.png');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.recordDetail {
  padding: 0 24px;
  .box {
    box-shadow: 0px 0px 25px 0px rgba(57, 57, 57, 0.06);
    border-radius: 30px;
    margin-top: 90px;
    padding: 0 20px;
    position: relative;
    padding-top: 130px;
    box-sizing: border-box;
    img {
      width: 128px;
      height: 128px;
      position: absolute;
      top: -50px;
      left: 50%;
      transform: translate(-50%, 0);
    }
    .title {
      text-align: center;
      font-size: 32px;
      font-weight: bold;
      color: #000000;
      position: absolute;
      top: 90px;
      left: 50%;
      transform: translate(-50%, 0);
      font-family: PingFang SC;
    }
    .list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 106px;
      border-bottom: 1px solid #efefef;
      font-size: 28px;
      color: #8d8d8d;
      &:last-child {
        border-bottom: 0;
      }
      .right {
        color: #333333;
        span {
          color: #85c226;
        }
      }
    }
  }
}
</style>
